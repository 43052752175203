import { BaseService, Service } from "/@/cool";
@Service("product")
class Sku extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["productSkuPage"] = prefix + "productSkuPage";
		this.permission["productAttrList"] = prefix + "productAttrList";
		this.permission["productList"] = prefix + "productList";
		this.permission["page"] = prefix + "page";
		this.permission["productSkuAdd"] = prefix + "productSkuAdd";
		this.permission["productSkuVersionProductList"] = prefix + "productSkuVersionProductList";
		this.permission["versionList"] = prefix + "versionList";
		this.permission["versionAdd"] = prefix + "versionAdd";
		this.permission["getSkuListByVersionIdAndProductId"] = prefix + "getSkuListByVersionIdAndProductId";
		this.permission["copyProductSkuByVersion"] = prefix + "copyProductSkuByVersion";
		this.permission["updateProductSku"] = prefix + "updateProductSku";
	}

	//sku翻页数据
	page(params: any) {
		return this.request({
			url: "/productSkuPage",
			method: "POST",
			data: {
				...params
			}
		});
	}

	//sku翻页数据
	productSkuPage(params: any) {
		return this.request({
			url: "/productSkuPage",
			method: "POST",
			data: {
				...params
			}
		});
	}
	//属性列表
	productAttrList(params: any) {
		return this.request({
			url: "/productAttrList",
			method: "POST",
			data: {
				...params
			}
		});
	}
	//产品列表
	productList(params: any) {
		return this.request({
			url: "/productList",
			method: "POST",
			data: {
				...params
			}
		});
	}
	//提交产品SKU数据
	addProductSku(data: any) {
		return this.request({
			url: "/productSkuAdd",
			method: "POST",
			data
		});
	}
	//获取版本号对应的产品ID
	getVersionProductList(params: any) {
		return this.request({
			url: "/productSkuVersionProductList",
			method: "POST",
			data: {
				...params
			}
		});
	}

	//获取版本号列表
	getVersionList(params: any) {
		return this.request({
			url: "/versionList",
			method: "POST",
			data: {
				...params
			}
		});
	}

	//增加版本号
	addVersion(params: any) {
		return this.request({
			url: "/versionAdd",
			method: "POST",
			data: {
				...params
			}
		});
	}

	//获取产品对应的所有的SKU列表
	getSkuListByVersionIdAndProductId(params: any) {
		return this.request({
			url: "/getSkuListByVersionIdAndProductId",
			method: "POST",
			data: {
				...params
			}
		});
	}

	//复制产品价格到目标版本
	copyProductSku(params: any) {
		return this.request({
			url: "/copyProductSkuByVersion",
			method: "POST",
			data: {
				...params
			}
		});
	}

	//修改指定产品的SKU价格
	updateProductSku(params: any) {
		return this.request({
			url: "/updateProductSku",
			method: "POST",
			data: {
				...params
			}
		});
	}

}
export default Sku;
