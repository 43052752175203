import { BaseService, Service } from "/@/cool";
@Service("shootOrders")
class ShootOrders extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["composePage"] = prefix + "composePage";
		this.permission["saveModelInfo"] = prefix + "saveModelInfo";
		this.permission["page"] = prefix + "page";
		this.permission["info"] = prefix + "info";
		this.permission["view"] = prefix + "view";
		this.permission["orderAddress"] = prefix + "orderAddress";
	}

	// 保存模型信息
	saveModelInfo(params: any) {
		return this.request({
			url: "/saveModelInfo",
			method: "POST",
			params
		});
	}
	// 保存模型信息
	view(params: any) {
		return this.request({
			url: "/view",
			method: "GET",
			params
		});
	}
	composePage(params: any) {
		return this.request({
			url: "/composePage",

			method: "GET",
			params
		});
	}
	// 获取订单地址
	orderAddress(params: any) {
		return this.request({
			url: "/orderAddress",
			method: "GET",
			params
		});
	}
}
export default ShootOrders;
