import { BaseService, Service } from "/@/cool";
@Service("products")
class Products extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["list"] = prefix + "list";
		this.permission["calc"] = prefix + "calc";
		
	}
	list(params: any) {
		return this.request({
			url: "/list",
			method: "GET",
			params
			
		});
	}
	calc(params: any) {
		return this.request({
			url: "/calc",
			method: "POST",
			data: params
			
		});
	}
}
export default Products;
