import { BaseService, Service } from "/@/cool";
@Service("composes")
class OrderCompose extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["products"] = prefix + "products";
		this.permission["calc"] = prefix + "calc";
		this.permission["orderConfirm"] = prefix + "orderConfirm";
	}

	// 子母单的产品
	products(data:any){
		return this.request({
			url: "/products",
			method: "POST",
			data
		});
	}
	// 子母单的计算价格
	calc(data:any){
		return this.request({
			url: "/calc",
			method: "POST",
			data
		});
	}
	// 子母单下单
	orderConfirm(data:any){
		return this.request({
			url: "/orderConfirm",
			method: "POST",
			data
		});
	}
}
export default OrderCompose;
